import qs from 'qs'

export const queryHomePage = () => {
  const query = qs.stringify(
    {
      fields: ['title'],
      populate: {
        titles: {
          fields: [
            'i18n_string_en',
            'i18n_string_es',
            'i18n_string_ca',
          ]
        },
        blocks: {
          populate: {
            introductions: {
              fields: [
                'i18n_richtext_en',
                'i18n_richtext_es',
                'i18n_richtext_ca',
              ]
            },
            descriptions: {
              fields: [
                'i18n_richtext_en',
                'i18n_richtext_es',
                'i18n_richtext_ca',
              ]
            },
            labels: {
              fields: [
                'i18n_string_en',
                'i18n_string_es',
                'i18n_string_ca',
              ]
            },
            link_labels: {
              fields: [
                'i18n_string_en',
                'i18n_string_es',
                'i18n_string_ca',
              ]
            },

            link: {
              populate: queryFragmentLinks
            },

            image: true
          }
        },
        hero_mux_assets: true,
        ...queryFragmentSeo
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
