<template>
  <div :class="$style.root">
    <LayoutTagLineComponent
      :class="$style.tagline"
    />

    <div :class="$style.videoMask" />

    <CoreMuxVideoComponent
      v-if="muxVideo && !isDev"
      :class="$style.video"
      :video="muxVideo"
      :includeAspectRatio="false"
    />

    <ClientOnly
      v-else
    >
      <video
        src="https://bvdb-general-purpose.s3.eu-central-1.amazonaws.com/xemeneies_corregit.mp4"
        autoplay=""
        muted=""
        loop=""
        playsinline=""
        :class="$style.video"
      />
    </ClientOnly>

    <SvgoLogo15
      :class="$style.logoHorizontal"
    />
    <SvgoLogo15Vertical
      :class="$style.logoVertical"
    />

    <NavigationTicketComponent :class="[$style.linkToNewsletter]" />
  </div>
</template>

<script setup>
import { sample } from 'lodash-es'
const runtimeConfig = useRuntimeConfig()

const isDev = runtimeConfig?.public?.env === 'development'

const props = defineProps({
  muxVideos: {
    type: Array,
    required: false
  }
})

const muxVideo = computed(() => {
  if (props?.muxVideos?.length > 0) {
    return sample(props?.muxVideos)
  } else {
    return null
  }
})
</script>

<style module lang='scss'>
.root {
  height: calc(var(--100svh) + 1px);
  width: 100%;

  background-color: var(--color--blue);

  position: relative;
  overflow: hidden;
}

.video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;

  --media-object-fit: cover;
  --media-object-position: center;
}

.videoMask {
  content: '';

  display: block;

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  backdrop-filter: var(--backdrop-filter--brightness--light);
}

.tagline {
  z-index: 3;
  position: absolute;

  top: 0;
  left: 0;

  margin: var(--unit--vertical) var(--unit--horizontal);

  color: var(--color--white);

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.logo {
  z-index: 3;
  position: absolute;

  overflow: visible;

  fill: var(--color--white);
}

.logoHorizontal {
  composes: logo;
  bottom: var(--unit--vertical);
  left: var(--unit--horizontal);
  right: var(--unit--horizontal);
  width: calc(100% - var(--unit--horizontal) * 2);

  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.logoVertical {
  composes: logo;
  top: calc(var(--unit--vertical) * 1);
  left: var(--unit--horizontal);
  height: calc(100% - var(--unit--vertical) * 2);

  display: none;
  @media (max-width: $breakpoint-s) {
    display: block;
  }
}

.linkToNewsletter {
  white-space: nowrap;
  overflow: hidden;

  z-index: 3;
  position: absolute;
  bottom: var(--unit--vertical);
  left: 50%;
  transform: translateX(-50%);

  display: none;
  @media (max-width: $breakpoint-s) {
    display: block;
  }
}
</style>
