<template>
  <PagesIndexBlocksLayoutComponent
    :label="label"
    :description="description"
    :linkLabel="linkLabel"
    routeName="news"
  >
    <div
      ref="listElement"
    >
      <LayoutSliderComponent
        :entries="childData?.data"
        :component="SharedThumbnailNewsComponent"
        linkType="dynamic"
      />
    </div>
    <PagesIndexBlocksLayoutLoadingComponent
      v-if="pending"
    />
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
import { SharedThumbnailNewsComponent } from '#components'

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

// 2
const query = {...baseRouteGetPublicationStateQuery(), pageSize: 6}
const { data: childData, execute, pending } = await useLazyAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryNewsEntryThumbnails(query) } } )
}, { immediate: false })

const listElement = ref(null)
const targetIsVisible = useElementVisibility(listElement)
watchOnce(targetIsVisible, (value)  => {
  if(value) {
    execute()
  }
})
</script>

<style module>
.root {
  padding: calc(var(--unit--vertical) * 3) var(--unit--horizontal);
}
</style>
