import qs from 'qs'
import { sampleSize } from 'lodash-es'

export const queryParticipantEntryThumbnailsRandom = (options: QueryManyOptions) => {
  const sortOptions = [
    'title:asc',
    'title:desc',
    'createdAt:asc',
    'createdAt:desc',
    'updatedAt:asc',
    'updatedAt:desc',
  ]

  const sortOption = sampleSize(sortOptions, 2)

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: sortOption,
      random: true,
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 6,
      },
      ...thumbnailParticipantEntry
    },
    {
      encodeValuesOnly: true
    }
  )

  return `participant-entries?${query}`
}
