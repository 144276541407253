<template>
  <PagesIndexBlocksLayoutComponent
    :label="label"
    :linkLabel="linkLabel"
    :description="description"
    routeName="programme"
    :class="$style.root"
  >
    <ul :class="$style.list" ref="listElement">
      <li
        v-for="entry in childData?.data"
        :key="entry.id"
        :class="$style.item"
        :data-dynamic-layout="childData?.data?.length > 3 ? '' : null"
      >
        <SharedThumbnailEventComponent
          :data="entry"
          linkType="dynamic"
          :linkToPogrammeSlug="true"
        />
      </li>
    </ul>

    <PagesIndexBlocksLayoutLoadingComponent
      v-if="pending"
    />
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
import { SharedThumbnailEventComponent } from '#components'
const route = useRoute()
import { sampleSize } from 'lodash-es'

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

// 2
const sortOptions = [
  'title:asc',
  'title:desc',
  'createdAt:asc',
  'createdAt:desc',
  'updatedAt:asc',
  'updatedAt:desc',
  'venue_entry:title:asc',
  'venue_entry:title:desc',
  'focus_week_entry:title:asc',
  'focus_week_entry:title:desc',
]

const sortOption = sampleSize(sortOptions, 2)

const query = {...baseRouteGetPublicationStateQuery(), sort: sortOption, permanentEventFilters: { permanent_event: { $eq: true } }, pageSize: 5 }

const { data: childData, execute, pending } = await useLazyAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryEventEntryThumbnails(query) } } )
}, { immediate: false })

const listElement = ref(null)
const targetIsVisible = useElementVisibility(listElement)
watchOnce(targetIsVisible, (value)  => {
  if(value) {
    execute()
  }
})
</script>

<style module lang="scss">
.root {
}

.heading {
  composes: reset-heading font-size-medium from global;
}

.list {
  composes: reset-list from global;
  display: flex;
  flex-wrap: wrap;

  padding: 0 calc(var(--unit--horizontal) / 2);
  gap: 0;

  margin: calc(var(--unit--vertical) * -1) 0;

  @media (max-width: $breakpoint-s) {
    margin: calc(var(--unit--vertical) * -.5) 0;
  }
}

.item {
  padding: calc(var(--unit--vertical) * 1) calc(var(--unit--horizontal) / 2);
}

.item:not([data-dynamic-layout]) {
  width: calc(100% / 3 );
  @media (max-width: $breakpoint-m) {
    width: 50%;
  }

  @media (max-width: $breakpoint-s) {
    padding: calc(var(--unit--vertical) * .5) calc(var(--unit--horizontal) / 2);
    width: 100%;
  }
}

.item[data-dynamic-layout] {
  width: calc(100% / 3 );
  @media (max-width: $breakpoint-m) {
    width: 50%;
  }

  @media (max-width: $breakpoint-s) {
    padding: calc(var(--unit--vertical) * .5) calc(var(--unit--horizontal) / 2);
    width: 100%;
  }
}

.item[data-dynamic-layout]:nth-child(-n+2) {
  width: 50%;

  @media (max-width: $breakpoint-s) {
    width: 100%;
  }
}
</style>
