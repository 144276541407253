<template>
  <div :class="$style.root">

    <PagesIndexHeroComponent
      :muxVideos="heroMuxAssets"
    />

    <PagesIndexBlocksComponent
      v-if="blocks?.length > 0"
      :blocks="blocks"
      :class="$style.blocks"
    />
  </div>
</template>

<script setup>
// 1
useHead({
  bodyAttrs: {
    style: '--site-header--position: fixed;'
  }
})

const props = defineProps({
  pageData: Object
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const heroMuxAssets = computed(() => {
  return localizedData?.value?.hero_mux_assets?.data
})


const blocks = computed(() => {
  return localizedData?.value?.blocks
})
</script>

<style module>
.root {
}

.blocks {
  position: relative;
  z-index: 1;
}
</style>
