<template>
  <div :class="$style.root">
    <LayoutDataPendingComponent :positionFixed="false" />
  </div>
</template>

<style module>
.root {
  padding: var(--unit--vertical) var(--unit--horizontal);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
