<template>
  <section :class="$style.root">
    <div :class="$style.texts">
      <CoreTextComponent
        v-if="introduction"
        :string="introduction"
        :class="$style.introduction"
      />

      <br v-if="introduction" />

      <CoreTextComponent
        v-if="description"
        :string="description"
        :class="$style.description"
      />

      <LayoutPillComponent
        v-if="linkObject"
        :name="linkObject?.routeReference?.name"
        :localizedData="linkObject"
        :class="$style.link"
        componentType="linkWithFallback"
        viewOption="reversed"
      >
        {{ linkObjectTitle || $t('shared.more') }}
      </LayoutPillComponent>
    </div>

    <div>
      <CoreImageComponent
        v-if="image"
        :image="image?.attributes"
        :class="$style.image"
      />
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  block: Object
})


const introduction = computed(() => i18nGetLocalizedString(props?.block?.introductions, 'i18n_richtext_en'))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

const linkObject = computed(() => navigationLinkItem(props?.block?.link))

const linkObjectTitle = computed(() => i18nGetLocalizedTitle(linkObject?.value))

const image = computed(() => props?.block?.image?.data)
</script>

<style module lang=scss>
.root {
  padding: calc(var(--unit--vertical) * 4) var(--unit--horizontal);

  --color--background: var(--color--blue);
  --color--primary: var(--color--white);

  background-color: var(--color--background);
  color: var(--color--primary);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: calc(var(--unit--horizontal) * 2);

  @media (max-width: $breakpoint-m) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: calc(var(--unit--vertical) * 4);
  }
}

.texts {
}

.introduction {
  composes: font-size-medium from global;
}

.description {
  composes: font-size-small from global;
}

.link {
  composes: reset-link button from global;
  margin-top: var(--unit--vertical);
  display: inline-block;
}

.image {
  flex-shrink: 0;
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--default));
  overflow: hidden;
}
</style>
