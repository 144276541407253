<template>
  <div>
    <PagesIndexComponent :pageData="data"  />

    <NuxtPage />
  </div>
</template>

<script setup>
const queryPath = queryHomePage()
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPath } }
  )
})

if (!data?.value?.data?.id || error?.value) {
  throw createError({ statusCode: 404 })
}

pageSetHead(data)
</script>
