<template>
  <section :class="$style.root">
    <div :class="$style.content">
      <div :class="$style.label">
        {{ label }}
      </div>

      <div :class="$style.texts">
        <CoreTextComponent
          v-if="description"
          :string="description"
          :class="$style.description"
        />

        <LayoutPillComponent
          v-if="linkObject"
          :name="linkObject?.routeReference?.name"
          :localizedData="linkObject"
          :class="$style.link"
          componentType="linkWithFallback"
          viewOption="reversed"
        >
          {{ linkObjectTitle || $t('shared.more') }}
        </LayoutPillComponent>
      </div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

const linkObject = computed(() => navigationLinkItem(props?.block?.link))

const linkObjectTitle = computed(() => i18nGetLocalizedTitle(linkObject?.value))
</script>

<style module lang="scss">
.root {
  composes: container--large from global;
  padding-top: calc(var(--unit--vertical) * 3);
  padding-bottom: calc(var(--unit--vertical) * 3);
}

.content {
  padding: calc(var(--unit--vertical) * 2) var(--unit--horizontal);

  --color--background: var(--color--blue);
  --color--primary: var(--color--white);

  background-color: var(--color--background);
  color: var(--color--primary);

  clip-path: inset(0% 0% 0% 0% round var(--border-radius--default));

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: calc(var(--unit--horizontal) * 2);

  @media (max-width: $breakpoint-m) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: calc(var(--unit--vertical) * 2);
  }
}

.label {
  composes: font-size-large from global;
}

.description {
  composes: font-size-small from global;
}

.link {
  composes: reset-link button from global;
  margin-top: var(--unit--vertical);
  display: inline-block;
}
</style>
