
<template>
  <section v-if="render" :class="$style.root">
    <template v-for="block in blocks" :key="block?.id">
      <PagesIndexBlocksAboutComponent v-if="block?.__component === 'homepage.about'" :block="block" />
      <PagesIndexBlocksCtaComponent v-if="block?.__component === 'homepage.cta'" :block="block" />
      <PagesIndexBlocksEventsComponent v-if="block?.__component === 'homepage.events'" :block="block" />
      <PagesIndexBlocksPermanentEventsComponent v-if="block?.__component === 'homepage.permanent-events'" :block="block" />
      <PagesIndexBlocksMapComponent v-if="block?.__component === 'homepage.map'" :block="block" />
      <PagesIndexBlocksNewsComponent v-if="block?.__component === 'homepage.news'" :block="block" />
      <PagesIndexBlocksParticipantsComponent v-if="block?.__component === 'homepage.participants'" :block="block" />
      <PagesIndexBlocksFocusWeeksComponent v-if="block?.__component === 'homepage.focus-weeks'" :block="block" />
    </template>
  </section>
</template>

<script setup>
const props = defineProps({
  blocks: {
    type: [Object, Array]
  }
})

const blocks = computed(() => {
  return props?.blocks
})

const render = computed(() => {
  return blocks?.value?.filter(i => {
    const localizedData = i18nGetLocalizedData(i)
    if (i?.__component === 'homepage.about') {
      return localizedData?.descriptions?.current_locale
    } else {
      return localizedData?.labels?.current_locale
    }
  })?.length > 0
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
}
</style>
