<template>
  <PagesIndexBlocksLayoutComponent
    v-if="isDesktop"
    :label="label"
    :description="description"
    :linkLabel="linkLabel"
    routeName="cities"
    :showMobileLink="false"
  >
    <MapUserEngagementContainerComponent
      :class="$style.container"
    >
      <div ref="mapElement">
        <LazyMapComponent
          v-if="showMap"
        />
      </div>
    </MapUserEngagementContainerComponent>
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
const { isDesktop } = useDevice();

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

const mapElement = ref(null)

const showMap = ref(false)

const targetIsVisible = useElementVisibility(mapElement)

watchOnce(targetIsVisible, (value)  => {
  if(value) {
    showMap.value = true
  }
})
</script>

<style module>
.container {
  margin: 0 var(--unit--horizontal);
}
</style>
