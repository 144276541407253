<template>
  <PagesIndexBlocksLayoutComponent
    :label="label"
    :linkLabel="linkLabel"
    :description="description"
    routeName="participants"
  >
    <LayoutSliderComponent
      v-if="childData?.data?.length"
      :entries="childData?.data"
      :component="SharedThumbnailParticipantComponent"
      linkType="dynamic"
    />
  </PagesIndexBlocksLayoutComponent>
</template>

<script setup>
import { SharedThumbnailParticipantComponent } from '#components'

const props = defineProps({
  block: Object
})

const label = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.labels))

const linkLabel = computed(() => i18nGetLocalizedString(i18nGetLocalizedData(props?.block)?.link_labels))

const description = computed(() => i18nGetLocalizedString(props?.block?.descriptions, 'i18n_richtext_en'))

// 2
const query = { ...baseRouteGetPublicationStateQuery() }
const { data: childData, execute } = await useLazyAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryParticipantEntryThumbnailsRandom(query) } } )
})

</script>

<style module>
.root {
  padding: calc(var(--unit--vertical) * 3) var(--unit--horizontal);
}
</style>
