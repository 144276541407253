<template>
  <div :class="$style.root" ref="root">
    <PagesIndexHeroContentComponent
      :class="$style.content"
      :muxVideos="muxVideos"
    />
  </div>
</template>

<script setup>
// 1
const root = ref(null)
const route = useRoute()
const fullPath = computed(() => route.fullPath)
const { isDesktop } = useDevice();

const { height: windowHeight } = useWindowSize()
const { height: rootHeight } = useElementSize(root)
const { y: scrollY } = useWindowScroll()

const setCssProperty = () => {
  if (!isDesktop) return

  const wHeight = process.client && parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--1svh')) ? parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--1svh')) * 100 : windowHeight.value
  const elementTop = root?.value?.getBoundingClientRect()?.top - (wHeight - rootHeight.value)

  if (elementTop < rootHeight.value) {
    const revealPercentage = (elementTop / rootHeight.value) * 100
    root?.value?.style?.setProperty('--translate-y', `${revealPercentage * -1 / 2}%`)
  }
}

onMounted(() => {
  setCssProperty()
})

watchDebounced([scrollY, fullPath], (value) => {
  setCssProperty()
}, { immediate: true, debounce: 0 })

// 2
const props = defineProps({
  muxVideos: {
    type: Array,
    required: false
  }
})
</script>

<style module>
.root {
  overflow: hidden;
  margin-top: calc( (var(--unit--vertical) * 2 + var(--font-small--line-height)) * -1);
}

.content {
  will-change: transform;
  /* transform: translateY(var(--translate-y, 0px)); */
  position: relative;
}

.content:after {
  content: '';

  height: calc(var(--unit--vertical) * 2);
  margin-bottom: calc(var(--unit--vertical) * -2);

  background-color: rgba(0, 0, 0, .05);
  mask-image: linear-gradient(black, transparent);

  display: block;
}
</style>
